<div *ngIf="options?.summary && service?.resourcesList?.additionalData">
  <app-list-summary
    [service]="service"
    [items]="service?.resourcesList?.additionalData"
    [selectedItems]="service?.selectedAdditionalData"
  >
  </app-list-summary>
</div>

<div *ngIf="options?.chart && !!(service?.chart | async)" [@showHide] class="mb-35">
  <mat-card>
    <mat-card-content>
      <app-charts
        [dataSets]="(service?.chart | async)?.datasets"
        [labels]="(service?.chart | async)?.labels"
        [options]="options?.chart?.options"
        [plugins]="options?.chart?.plugins"
        [legend]="options?.chart?.legend"
        [type]="options?.chart?.chartType"
        [colors]="options?.chart?.colors"
        [height]="options?.chart?.height"
      ></app-charts>
    </mat-card-content>
  </mat-card>
</div>

<div class="table-overflow">
  <div
    class="tbl-content"
    #tbodyScroller
    [class.infinite-scrolling]="options?.infiniteScrolling"
    ngxInfiniteScroller
    strategy="scrollingToBottom"
    initialScrollPosition="TOP"
    (onScrollDown)="infiniteScrollLoadMore()"
    [perfectScrollbar]="infiniteScrollConfig"
  >
    <div
      mdePopoverTarget
      #mdeFiltersTarget="mdePopoverTarget"
      class="fixed_table_actions full-width position-relative"
    ></div>
    <table
      class="table table-hover table-style"
      [ngClass]="{ no_data: rows?.data?.length === 0 }"
      [class.infinite-scrolling]="options?.infiniteScrolling"
      [class.scrollable]="pagination?.currentPage !== pagination?.lastPage"
    >
      <thead class="tbl-header">
        <tr>
          <ng-container *ngIf="options?.selectable && rows?.data?.length > 0">
            <ng-container
              *ngIf="
                service?.permission?.all ||
                service?.permission?.delete ||
                service?.permission?.manageArchived ||
                options?.exportList ||
                options?.forceSelectable
              "
            >
              <th class="select-all">
                <mat-checkbox
                  value=""
                  color="primary"
                  class="custom-style-1"
                  (change)="doAction('select', rowsIds(rows?.data))"
                  [checked]="service.selectedItems.length === rows?.data.length"
                  [afaqyHeatmap]="{ page: service?.cid, target: 'list-check-all' }"
                ></mat-checkbox>
              </th>
            </ng-container>
          </ng-container>
          <ng-container *ngFor="let column of columns; let i = index">
            <ng-container
              *ngIf="
                column.list &&
                !column.list.hidden &&
                (!column.list.showIf ||
                  (options.archivedList && (column.list.showIf === 'both' || column.list.showIf === 'archive')) ||
                  (!options.archivedList && (column.list.showIf === 'both' || column.list.showIf === 'active')))
              "
            >
              <!-- to-do : fix appending th width/width_percent in case of inline-filters appears -->
              <th
                [fxHide.lt-lg]="column?.list?.hiddenOnScreenSize?.medium ? true : false"
                [fxHide.lt-xl]="column?.list?.hiddenOnScreenSize?.large ? true : false"
                fxShow
                [style.width.px]="column.list.width ? column.list.width : ''"
                [style.width.%]="column.list.width_percent ? column.list.width_percent : ''"
                [ngClass]="{ 'th-hidden': column.list.width === 0, 'th-filters-inline': service.showInlineFilters }"
                [class]="column?.list?.cssClass || ''"
              >
                <div
                  class="d-flex align-items-center flex-items-center"
                  [ngClass]="{ sortable: column.list.sortable, 'filters-inline': column.list.searchableInline }"
                  [afaqyHeatmap]="{ page: service?.cid, target: 'filters-' }"
                >
                  <span class="table-column-header" *ngIf="!column.list.searchableInline || !service.showInlineFilters">
                    <ng-container>
                      <span>{{
                        service.cid + '.' + (column.list.name ? column.list.name : column.name) | translate
                      }}</span>
                      <button
                        *ngIf="column.list.sortable"
                        mat-icon-button
                        (click)="column.list.sortable ? sortList(column.name) : $event.stopPropagation()"
                        [afaqyHeatmap]="{ page: service?.cid, target: 'sort-inline-' + column.name }"
                      >
                        <mat-icon class="mdi-icon" svgIcon="swap-vertical" [attr.size]="'14px'"></mat-icon>
                      </button>
                    </ng-container>
                  </span>
                  <app-filters-inline
                    *ngIf="column.list.searchableInline && service.showInlineFilters"
                    [column]="column"
                    [service]="service"
                    [cid]="service.cid"
                    [passedRows]="oPassedRows"
                    (searchResult)="onSearch($event)"
                  >
                  </app-filters-inline>
                </div>
              </th>
            </ng-container>
          </ng-container>
          <!-- <th
          *ngIf="hasActions()"
          class="actions-head action-column-width"
          [ngClass]="options.actionsCssClass"
          (click)="hasFilters() ? filterSidenav.toggle() : ''"
        >
          <div #actionsTh class="filter-action" *ngIf="hasFilters()">
            <mat-icon>tune</mat-icon>
            <span>{{ 'common.filters' | translate }}</span>
          </div>
        </th> -->
          <th class="action-column-width" [ngClass]="options.actionsCssClass" *ngIf="options?.customActions?.length">
            <span class="actions-th">{{ 'common.actions' | translate }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="!service.notFoundResult && rows?.data?.length === 0 && !service?.searchQuery?.length">
          <tr>
            <td colspan="100%">
              <div fxLayout="column" fxLayoutAlign="center center">
                <div class="empty-list mt-3">
                  <mat-icon class="icon-list" [svgIcon]="'iconEmptyList'"></mat-icon>
                  <h2 class="title">
                    {{ 'common.emptyList' | translate }}
                  </h2>
                  <p class="des mb-3">
                    {{ 'common.desEmptyList' | translate }}
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
        <ng-container
          *ngIf="
            (service.notFoundResult && rows?.data?.length === 0) ||
            (service?.searchQuery?.length && rows?.data?.length === 0)
          "
        >
          <tr>
            <td colspan="100%">
              <div fxLayout="column" fxLayoutAlign="center center">
                <div class="empty-list mt-3">
                  <mat-icon class="icon-list" [svgIcon]="'notFoundDataIcon'"></mat-icon>
                  <h2 class="title">
                    {{ 'common.titleNotFoundResult' | translate }}
                  </h2>
                  <p class="des mb-3">
                    {{ 'common.desNotFoundResult' | translate }}
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
        <tr
          *ngFor="let row of rows?.data; let isFirst = first"
          [ngClass]="{ 'selected-row': options?.selectable && service.checkSelected(row.id) }"
        >
          <ng-container
            *ngIf="
              service?.permission?.all ||
              service?.permission?.delete ||
              service?.permission?.manageArchived ||
              options?.exportList ||
              options?.forceSelectable
            "
          >
            <ng-container *ngIf="options?.selectable">
              <td class="select-row">
                <mat-checkbox
                  [checked]="service.checkSelected(row.id)"
                  color="primary"
                  class="custom-style-1"
                  (change)="doAction('select', row.id)"
                  [attr.testId]="'cb-' + row?.id"
                ></mat-checkbox>
              </td>
            </ng-container>
          </ng-container>
          <ng-container *ngFor="let column of columns; index as columnIndex">
            <ng-container
              *ngIf="
                column.list &&
                !column.list.hidden &&
                (!column.list.showIf ||
                  (options.archivedList && (column.list.showIf === 'both' || column.list.showIf === 'archive')) ||
                  (!options.archivedList && (column.list.showIf === 'both' || column.list.showIf === 'active')))
              "
            >
              <!--              [style.width.px]="column.list.width"-->
              <!--              [style.max-width.px]="column.list.width"-->
              <td
                tourAnchor="{{ isFirst && column?.list?.action ? service?.cid + '-view' : '' }}"
                [style.height.px]="column.list.height"
                class="cell-container"
                [fxHide.lt-lg]="column?.list?.hiddenOnScreenSize?.medium ? true : false"
                [fxHide.lt-xl]="column?.list?.hiddenOnScreenSize?.large ? true : false"
                fxShow
                [ngClass]="column?.list?.cellCssClass"
              >
                <div class="cell-inner-container" [ngClass]="innerCellCssConditions(row, column)">
                  <!-- item prefix start -->
                  <div class="item-prefix" [ngClass]="column?.list?.prefixCssClass" *ngIf="column?.list?.prefix">
                    <div
                      *ngFor="let column of column?.list?.prefix; index as prefix_columnIndex"
                      [class.clickable]="column?.list?.action"
                      [ngClass]="cssClassConditions(row, column, [column?.list?.cellCssClass])"
                      (click)="
                        service?.listActions(
                          column?.list?.action?.name,
                          row,
                          column,
                          column?.list?.action?.fnRow,
                          column?.list?.action?.fn
                        )
                      "
                    >
                      <ng-container *ngIf="showConditionsChecker(row, column)">
                        <app-list-grid-content
                          [row]="row"
                          [column]="column"
                          [options]="options"
                          [controller]="service.cid"
                          [columnIndex]="prefix_columnIndex"
                          [service]="service"
                        ></app-list-grid-content>
                      </ng-container>
                    </div>
                  </div>
                  <!-- item prefix end -->

                  <!-- item content start -->

                  <div
                    class="item-content"
                    [class.clickable]="column?.list?.action"
                    [ngClass]="cssClassConditions(row, column)"
                    (click)="
                      service?.listActions(
                        column?.list?.action?.name,
                        row,
                        column,
                        column?.list?.action?.fnRow,
                        column?.list?.action?.fn
                      )
                    "
                  >
                    <div class="vertical-helper">
                      <ng-container>
                        <app-list-grid-content
                          [row]="row"
                          [column]="column"
                          [options]="options"
                          [controller]="service.cid"
                          [columnIndex]="columnIndex"
                          [service]="service"
                        ></app-list-grid-content>
                      </ng-container>

                      <!-- item Additional Content start -->
                      <div
                        class="item-additional-content clearfix"
                        [ngClass]="column?.list?.additionalContentCssClass"
                        *ngIf="column?.list?.additionalContent"
                      >
                        <ng-container *ngIf="showConditionsChecker(row, column)">
                          <app-list-grid-content
                            *ngFor="
                              let column of column?.list?.additionalContent;
                              index as additionalContent_columnIndex
                            "
                            [class.clickable]="column?.list?.action"
                            [ngClass]="column?.list?.cellCssClass"
                            appHasChildNodes
                            (click)="
                              service?.listActions(
                                column?.list?.action?.name,
                                row,
                                column,
                                column?.list?.action?.fnRow,
                                column?.list?.action?.fn
                              )
                            "
                            [row]="row"
                            [column]="column"
                            [options]="options"
                            [controller]="service.cid"
                            [columnIndex]="additionalContent_columnIndex"
                            [service]="service"
                          ></app-list-grid-content>
                        </ng-container>
                      </div>
                      <!-- item Additional Content end -->
                    </div>
                  </div>

                  <!-- item content end -->

                  <!-- item suffix start -->
                  <div class="item-suffix" [ngClass]="column?.list?.suffixCssClass" *ngIf="column?.list?.suffix">
                    <div
                      *ngFor="let column of column?.list?.suffix; index as suffix_columnIndex"
                      [class.clickable]="column?.list?.action"
                      [ngClass]="cssClassConditions(row, column, [column?.list?.cellCssClass])"
                      (click)="
                        service?.listActions(
                          column?.list?.action?.name,
                          row,
                          column,
                          column?.list?.action?.fnRow,
                          column?.list?.action?.fn
                        )
                      "
                    >
                      <ng-container *ngIf="showConditionsChecker(row, column)">
                        <app-list-grid-content
                          [row]="row"
                          [column]="column"
                          [options]="options"
                          [controller]="service.cid"
                          [columnIndex]="suffix_columnIndex"
                          [service]="service"
                        ></app-list-grid-content>
                      </ng-container>
                    </div>
                  </div>
                  <!-- item suffix end -->
                </div>
              </td>
            </ng-container>
          </ng-container>

          <!--          [style.width]="actionsColumnWidth + 'px'"-->
          <td *ngIf="hasActions()" class="action-column-width actions-data-row">
            <div class="actions-buttons">
              <!--Custom Actions Start-->
              <app-list-custom-actions [columns]="columns" [options]="options" [service]="service" [row]="row">
              </app-list-custom-actions>
              <!--Custom Actions End-->
            </div>
          </td>
        </tr>

        <tr *ngIf="options?.infiniteScrolling">
          <td
            [colSpan]="
              options?.selectable && hasActions() && columns?.length
                ? columns?.length + 2
                : (options?.selectable || hasActions()) && columns?.length
                ? columns?.length + 1
                : columns?.length
                ? columns?.length
                : 1
            "
            [ngClass]="{ 'hidden-td': pagination.currentPage === pagination.lastPage }"
          >
            <div>
              <app-pagination-infinite-scroll
                [service]="service"
                [options]="options"
                (paginationChanged)="setPagination($event)"
              ></app-pagination-infinite-scroll>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!--<mde-popover-->
<!--  #appPopover="mdePopover"-->
<!--  [mdePopoverArrowWidth]="0"-->
<!--  [mdePopoverOverlapTrigger]="false"-->
<!--  mdePopoverPositionY="below"-->
<!--  mdePopoverPositionX="before"-->
<!--&gt;-->
<!--  <app-filters [columns]="columns" [service]="service" [listOptions]="options"></app-filters>-->
<!--</mde-popover>-->

<mat-sidenav-container class="filterSideNavContainer" [hasBackdrop]="true">
  <mat-sidenav #filterSidenav mode="side" opened="false" [fixedInViewport]="true" position="end" class="filterSideNav">
    <app-filters
      [columns]="columns"
      [service]="service"
      [listOptions]="options"
      [sideNav]="filterSidenav"
    ></app-filters>
  </mat-sidenav>
</mat-sidenav-container>
