import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DevicesService } from '@app/clients/devices/devices.service';
import { ApiRequestService } from '@app/core/http/api-request.service';
import { SharedService } from '@app/shared/services/shared.service';
import { environment } from '@env/environment';
import { take } from 'rxjs';

@Component({
  selector: 'app-assign-command',
  templateUrl: './assign-command.component.html',
  styleUrls: ['./assign-command.component.scss'],
})
export class AssignCommandComponent implements OnInit {
  assignCommandForm: UntypedFormGroup = new UntypedFormGroup({});

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiRequestService,
    private shared: SharedService,
    private matDialogRef: MatDialogRef<AssignCommandComponent>
  ) {}

  ngOnInit(): void {
    this.setAssignCommandForm();
    this.assignCommandForm.controls.commandId.valueChanges.subscribe((res) => {
      res && this.getCommandById();
    });
  }

  getCommandById() {
    this.api
      .get(`${environment.serverUrl}commands/${this.assignCommandForm.controls.commandId.value}`)
      .subscribe((res: any) => {
        this.assignCommandForm.controls.channel.setValue(res?.data?.channel);
      });
  }

  setAssignCommandForm() {
    this.assignCommandForm = this.fb.group({
      commandId: new UntypedFormControl(null, Validators.required),
      channel: new UntypedFormControl({ value: null, disabled: true }),
    });
  }

  // deviceId/ commandId / vehicleId /

  assignCommand() {
    let data = this.assignCommandForm.value;
    (data.deviceId = this.data.row.id),
      (data.commandId = this.assignCommandForm.controls.commandId.value),
      (data.vehicleId = this.data.row.assetId),
      delete data.channel;
    this.api.post(`${environment.serverUrl}commands/assigns`, data).subscribe((res: any) => {
      this.closeDialog(res);
    });
  }

  closeDialog(data?: any) {
    this.matDialogRef.close({ data: data });
  }
}
