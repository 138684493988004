<section class="assign-command">
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <h3>{{ 'device-settings.commands.create' | translate }}</h3>
    <mat-icon class="material-symbols-outlined close-dialog" (click)="closeDialog()"> close </mat-icon>
  </div>

  <form [formGroup]="assignCommandForm" (ngSubmit)="assignCommand()">
    <div>
      <app-core-form-content
        [cid]="data?.service.cid"
        [service]="data?.service"
        [field]="data?.service.getFieldByName('commandId')"
        [form]="assignCommandForm"
        (showHideChanges)="data?.service.showHideChanges($event)"
        [isEdit]="data?.service?.isEdit"
        [isClone]="data?.service?.isClone"
        [isSubmitted]="data?.service?.isSubmitted"
        (UpdateFormFieldValueEmitter)="data?.service.UpdateFormFieldValue($event)"
      >
      </app-core-form-content>

      <app-core-form-content
        [cid]="data?.service.cid"
        [service]="data?.service"
        [field]="data?.service.getFieldByName('channel')"
        [form]="assignCommandForm"
        (showHideChanges)="data?.service.showHideChanges($event)"
        [isEdit]="data?.service?.isEdit"
        [isClone]="data?.service?.isClone"
        [isSubmitted]="data?.service?.isSubmitted"
        (UpdateFormFieldValueEmitter)="data?.service.UpdateFormFieldValue($event)"
      >
      </app-core-form-content>
    </div>

    <div class="action-buttons">
      <button mat-button class="cancel-btn button" (click)="closeDialog()">
        {{ 'common.cancel' | translate }}
      </button>
      <button
        mat-button
        class="submit-btn button"
        [disabled]="!assignCommandForm.valid || assignCommandForm.pristine"
        [ngClass]="{ disabled: assignCommandForm.invalid || assignCommandForm.pristine }"
      >
        {{ 'common.submit' | translate }}
      </button>
    </div>
  </form>
</section>
