import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '@app/material.module';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { AppTooltipDirective } from '@app/shared/directives/app-tooltip-directive';
import { ListGridComponent } from '@app/shared/components/list-grid/list-grid.component';
import { ListGridOptionsComponent } from '@app/shared/components/list-grid-options/list-grid-options.component';
import { PaginationComponent } from '@app/shared/components/pagination/pagination.component';
import { PaginationInfiniteScrollComponent } from '@app/shared/components/pagination-infinite-scroll/pagination-infinite-scroll.component';

import { FormFooterComponent } from '@app/shared/components/form-footer/form-footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SecuredDirective } from '@app/shared/directives/secured.directive';
import { FiltersComponent } from './components/filters/filters.component';
import { FiltersInlineComponent } from './components/filters-inline/filters-inline.component';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { PermissionsComponent } from './components/permissions/permissions.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MdePopoverModule } from '@material-extended/mde';
import { DialogComponent } from './components/dialog/dialog.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ListGridContentComponent } from '@app/shared/components/list-grid-content/list-grid-content.component';
import { MdiIconComponent } from './components/mdi-icon/mdi-icon.component';
import { DynamicDialogComponent } from '@app/shared/components/dynamic-dialog/dynamic-dialog.component';
import { CustomDialogComponent } from '@app/shared/components/custom-dialog/custom-dialog.component';
import { PhotoGalleryComponent } from './components/photo-gallery/photo-gallery.component';
import { CommentsComponent } from './components/comments/comments.component';
// import { LightboxModule } from '@ngx-gallery/lightbox';
import { LightboxModule } from 'ngx-lightbox';
import { GalleryModule } from '@ngx-gallery/core';
import { NgxInfiniteScrollerModule } from 'ngx-infinite-scroller';
import { DocumentsComponent } from './components/documents/documents.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { GroupByPipe, NgPipesModule, TimeAgoPipe } from 'ngx-pipes';
import { CoreFormContentComponent } from '@app/shared/components/core-form-content/core-form-content.component';
import { HasChildNodesDirective } from './directives/has-child-nodes.directive';
import { NgProgressModule } from 'ngx-progressbar';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { TippyModule } from 'ng-tippy';
import { HighlightsComponent } from './components/highlights/highlights.component';
import { TimeagoModule } from 'ngx-timeago';
import { ImportDialogComponent } from '@app/shared/components/import-dialog/import-dialog.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import {
  PerfectScrollbarModule,
  PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG,
} from 'ngx-perfect-scrollbar';
import { ListMultipleOperationsComponent } from './components/list-multiple-operations/list-multiple-operations.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { SliderRangeComponent } from './components/slider-range/slider-range.component';
import { DateTimeComponent } from './components/date-time/date-time.component';
import { NgSelectApiComponent } from './components/ng-select-api/ng-select-api.component';
import { DateTimeRangeComponent } from './components/date-time-range/date-time-range.component';
import { ListGridMaterialComponent } from '@app/shared/components/list-grid-material/list-grid-material.component';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { ChartsComponent } from './components/charts/charts.component';
import { NgChartsModule } from 'ng2-charts';
import { NumericMinMaxDirective } from './directives/numeric-min-max.directive';
import { TimePickerComponent } from '@app/shared/components/time-picker/time-picker.component';
import { DatePickerComponent } from '@app/shared/components/date-picker/date-picker.component';
import { InputValidationErrorsComponent } from '@app/shared/components/input-validation-errors/input-validation-errors.component';
import { LocalizedDatePipe } from './pipes/localize-date.pipe';
import {
  DatePickerV2Component,
  DatePickerV2TemplateComponent,
} from '@app/shared/components/date-picker-v2/date-picker-v2.component';
import { EndDialogComponent } from '@app/shared/components/end-dialog/end-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImgCropperDialogComponent } from './components/img-cropper-dialog/img-cropper-dialog.component';
import { AppDragDirective } from './directives/app-drag.directive';
import { NgxImageCompressService } from 'ngx-image-compress';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { ImgUploadDetailsComponent } from './components/img-upload-details/img-upload-details.component';
import { NgSelectActiveComponent } from './components/ng-select-active/ng-select-active.component';
import { ReportFilterDialogComponent } from '@app/shared/components/report-filter-dialog/report-filter-dialog.component';
import { ListCustomActionsComponent } from './components/list-custom-actions/list-custom-actions.component';
import { FormPopupComponent } from './components/ng-select-active/form-popup/form-popup.component';
// import { NgSelectFormFieldControlDirective } from '@app/shared/directives/ng-select-mat.directive';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { GoogleMapsModule } from '@angular/google-maps';
import { LocationPickerComponent } from '@app/shared/components/location-picker/location-picker.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { LeafletMapComponent } from '@app/shared/components/leaflet-map/leaflet-map.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { LeafletDrawComponent } from '@app/shared/components/leaflet-draw/leaflet-draw.component';
import {
  MAT_COLOR_FORMATS,
  NgxMatColorPickerModule,
  NGX_MAT_COLOR_FORMATS,
} from '@angular-material-components/color-picker';
import { MaterialColorPickerComponent } from './components/material-color-picker/material-color-picker.component';
import { HeatmapsIdentifiersModule } from '@afaqy-libs/heatmaps-identifiers';
import { environment } from '@env/environment';
import { pairsImpure } from './pipes/pairs-impure.pipe';
import { FiltersButtonComponent } from '@app/shared/components/filter-button/filter-button.component';
import { MultiCheckboxComponent } from './components/multi-checkbox/multi-checkbox.component';
import { AssignCommandComponent } from './components/assign-command/assign-command.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: true,
};

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    FlexLayoutModule,
    MaterialModule,
    CommonModule,
    TranslateModule,
    RouterModule,
    BreadcrumbModule,
    NgxSpinnerModule,
    MdePopoverModule,
    ColorPickerModule,
    GalleryModule,
    LightboxModule,
    NgxInfiniteScrollerModule,
    NgxFileDropModule,
    NgPipesModule,
    NgProgressModule,
    NgSelectModule,
    NgOptionHighlightModule,
    TippyModule,
    TimeagoModule,
    PerfectScrollbarModule,
    NgxSliderModule,
    NgxMaterialTimepickerModule,
    // NgxMaterialTimepickerModule.setLocale('ar-AE'),
    NgxDaterangepickerMd.forRoot(),
    NgChartsModule,
    ImageCropperModule,
    TourMatMenuModule,
    NgxMatIntlTelInputComponent,
    GoogleMapsModule,
    LeafletModule,
    LeafletDrawModule,
    GooglePlaceModule,
    NgxMatColorPickerModule,
    HeatmapsIdentifiersModule.forRoot(environment?.heatmapsConfig),
  ],
  declarations: [
    // Components
    ListGridComponent,
    ListGridMaterialComponent,
    ListGridContentComponent,
    PaginationComponent,
    PaginationInfiniteScrollComponent,
    FormFooterComponent,
    NavMenuComponent,
    ListGridOptionsComponent,
    ImportDialogComponent,
    ReportFilterDialogComponent,
    LeafletDrawComponent,
    // Directives
    SecuredDirective,
    AppTooltipDirective,
    FiltersComponent,
    FiltersInlineComponent,
    FiltersButtonComponent,
    PermissionsComponent,
    DialogComponent,
    DynamicDialogComponent,
    CustomDialogComponent,
    EndDialogComponent,
    ImportDialogComponent,
    MdiIconComponent,
    PhotoGalleryComponent,
    CommentsComponent,
    DocumentsComponent,
    CoreFormContentComponent,
    HighlightsComponent,
    HasChildNodesDirective,
    DateRangeComponent,
    SliderRangeComponent,
    ListMultipleOperationsComponent,
    DateTimeComponent,
    NgSelectApiComponent,
    TimePickerComponent,
    DatePickerComponent,
    DatePickerV2Component,
    DatePickerV2TemplateComponent,
    InputValidationErrorsComponent,
    DateTimeRangeComponent,
    OnlyNumbersDirective,
    ChartsComponent,
    NumericMinMaxDirective,
    LocalizedDatePipe,
    ImgCropperDialogComponent,
    AppDragDirective,
    ImgUploadDetailsComponent,
    NgSelectActiveComponent,
    // NgSelectFormFieldControlDirective,
    ListCustomActionsComponent,
    FormPopupComponent,
    LocationPickerComponent,
    LeafletMapComponent,
    MaterialColorPickerComponent,
    pairsImpure,
    MultiCheckboxComponent,
    AssignCommandComponent,
  ],
  exports: [
    // Modules
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    TranslateModule,
    BreadcrumbModule,
    NgxSpinnerModule,
    MdePopoverModule,
    // Components
    ListGridComponent,
    ListGridMaterialComponent,
    ListGridContentComponent,
    ListGridOptionsComponent,
    ImportDialogComponent,
    PaginationComponent,
    PaginationInfiniteScrollComponent,
    FormFooterComponent,
    NavMenuComponent,
    FiltersComponent,
    FiltersInlineComponent,
    PermissionsComponent,
    DialogComponent,
    DynamicDialogComponent,
    CustomDialogComponent,
    ImportDialogComponent,
    ReportFilterDialogComponent,
    ColorPickerModule,
    PhotoGalleryComponent,
    GalleryModule,
    LightboxModule,
    CommentsComponent,
    DocumentsComponent,
    NgPipesModule,
    CoreFormContentComponent,
    NgxFileDropModule,
    NgProgressModule,
    NgSelectModule,
    NgOptionHighlightModule,
    TippyModule,
    HighlightsComponent,
    NgxMaterialTimepickerModule,
    PerfectScrollbarModule,
    ListMultipleOperationsComponent,
    ChartsComponent,
    NgChartsModule,
    LocalizedDatePipe,
    AppDragDirective,
    LocationPickerComponent,
    LeafletMapComponent,
    LeafletDrawComponent,
    HeatmapsIdentifiersModule,
    pairsImpure,
    MultiCheckboxComponent,
    TimeagoModule,
    FiltersButtonComponent,
    AssignCommandComponent,
  ],
  providers: [
    NgPipesModule,
    GroupByPipe,
    TimeAgoPipe,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    NgxImageCompressService,
    { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
  ],
})
export class SharedModule {}
